import getData from "@/__main__/get-data.mjs";
import TftPatches from "@/data-models/tft-patches.mjs";
import * as API from "@/game-tft/api.mjs";
import { setPatches } from "@/game-tft/constants.mjs";
import getSetFiltersPatch from "@/game-tft/get-set-filters-patch.mjs";
import fetchStatsDataV2 from "@/game-tft/tft-fetch-stats-v2.mjs";

export default async function fetchData([champion], searchParams) {
  const { set, statFilters, isFutureSet } = getSetFiltersPatch(
    searchParams,
    "indiv_units",
  );
  const options = {
    networkBackOffTime: 1000 * 60 * 60, // 1 hour
  };
  const filters = new URLSearchParams(statFilters);
  filters.append("api_name", champion);
  let { patch } = await getData(
    isFutureSet ? API.getLatestPBEPatch() : API.getLatestPatch(),
    TftPatches,
    isFutureSet ? ["tft", "pbePatch"] : ["tft", "latestPatch"],
    options,
  );
  const dataSet =
    (searchParams?.get("set") && `set${searchParams?.get("set")}`) ||
    setPatches[patch] ||
    set;
  const selectedDiffSet =
    searchParams?.get("set") && dataSet !== setPatches[patch];
  const hasPatchFromConst =
    selectedDiffSet &&
    Object.entries(setPatches).some(([_, s]) => s === dataSet);
  if (hasPatchFromConst) {
    patch = Object.entries(setPatches)
      .filter(([_, s]) => s === dataSet)
      .slice(-1)[0][0];
  }
  return fetchStatsDataV2({
    patch,
    page: "indiv_units",
    filters: filters.toString().replace(/%2B/g, "+"),
    set: dataSet,
    options,
  });
}
